* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  max-width: 1024px;
  margin: 0 auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sub-text {
  font-size: 12pt;
  color: #8C88AA
}

.flicker {
  position: absolute;
  right: 0;
  bottom: 0;
}

.flicker img {
  width: 150px;
}

img {
  display: block;
}