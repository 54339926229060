ol {
    list-style-position: outside;
    padding: 0 50px;
}

ol li {
    line-height: 1.2em;
    margin-bottom: 0.5em;
}

.reasons li:last-child {
    margin-bottom: 0;
}

.header::before {
    content: '';
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content {
    color: black;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: rgb(18, 39, 48);
    background-color: #C6E1F7;
    background-image: url("https://s3-us-west-2.amazonaws.com/bird-alberni/footer-bird.jpg");
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -10;
    color: white;
    padding: 20px;
    text-align: right;
    font-size: 10pt;
    height: 30vw;
}

.footer.footer-location {
    background-image: url("https://s3-us-west-2.amazonaws.com/bird-alberni/kayak-at-estuary-.jpg");
}

.footer-loc {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgb(18, 39, 48);
    z-index: -10;
    color: white;
    padding: 20px;
    font-size: 10pt;
}

.title {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
}

.title.info {
    text-transform: capitalize;
    line-height: 0.9em;
}

.header .title {
    display: inline-block;
    color: gray;
    font-size: 18px;
    padding: 10px;
    font-weight: 300;
    z-index: 100;
    margin: 0 0 0 20px;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.nav img {
    width: 22px;
    margin-left: 10px;
    margin-right: 20px;
}

.header .nav ul {
    display: inline-block;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: gray;
}
  
.header .nav ul li {
    display: inline-block;
    padding: 10px;
}

a.mail {
    text-decoration: none;
    color: #FFD114;
    cursor: pointer;
    font-style: italic;
}

a.link {
    text-decoration: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.header a {
    color: gray;
    text-decoration: none;
    transition: color 0.5s;
}

.header a:hover {
    color: white;
    transition: color 0.5s;
}

  
.bird {
    font-weight: lighter;
}

.alberni {
    font-weight: bold;
}

.jumbotron {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    color: white;
    position: relative;
    top: 50px;
    min-height: 200px;
  }

.jumbotron::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.jumbotron .title, .location-title {
    color: rgba(255, 255, 255, 0.733);
    font-size: 10vw;
    font-weight: 300;
    margin: 0 50px;
    position: relative;
    bottom: -10px;
    left: 0;
    text-align: left;
}

.location-title {
    font-size: 7vw;
    text-transform: capitalize;
}

.jumbotron > * {
    z-index: 10;
}

.locations {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

.locations ul {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

  
.locations li {
    display: flex;
    margin-top: 40px;
    /*margin-right: 20px; */
}

.locations li:last-child {
    margin-right: 0px;
}
  
.location {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 275px;
    text-transform: uppercase;
    padding: 20px;
    font-size: 1.2em;
    font-family: Helvetica, Roboto;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.75);
}

.location:hover {
    background-size: 120%;
    transition: all 0.5s;
    cursor: pointer;
    color: white;
}

.main-info {
    padding: 50px;
    padding-top: 70px;
    text-align: left;
    background-color: rgb(18, 39, 48);
    color: white;
    position: relative;
    overflow: hidden;
}

.main-info img {
    width: 100%;
}

.image-container.left {
    float: left;
    clear: left;
    width: 33%;
    margin-right: 1em;
    margin-bottom: 1em;
}

.image-container.right {
    float: right;
    clear: right;
    width: 33%;
    margin-left: 1em;
    margin-bottom: 1em;
}

.image-container.full {
    float: none;
    width: 100%;
    margin-bottom: 1em;
}

.main-info p:not(.credit) {
    line-height: 1.4em;
    padding-bottom: 2em;
}

.info-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 2em;
}

.info-left-image {
    width: 150px;
    margin-right: 20px;
}

.info-block p {
    line-height: 1.4em;
}

.main-info p:last-child:not(.credit) {
    padding-bottom: 0;
}

.map {
    height: 400px;
    width: 100%;
}

.site-info {
    background-image: url("https://s3-us-west-2.amazonaws.com/bird-alberni/burde-street-ponds-faded.jpg");
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.home, .location-container, .header, .about {
    background-image: url("https://s3-us-west-2.amazonaws.com/bird-alberni/background-leafy.jpg");
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -10;
}

.home.mobile, .location-container.mobile, .header.mobile, .about.mobile {
    background-image: url("https://s3-us-west-2.amazonaws.com/bird-alberni/banner-barred-owl.jpg");
    background-attachment: scroll;
    background-size: contain;
}

.header.mobile {
    background-size: cover;
}

.header.mobile::before {
    background-color: black;
}

.location {
    z-index: 0;
}

.header {
    width: 100%;
    max-width: 1024px;
    text-align: left;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 20;
    height: 50px;
}

.directions {
    background-color: rgb(238, 238, 238);
    color:  rgb(18, 39, 48);
    text-align: left;
    padding: 50px;
}

.directions .title {
    font-size: 2em;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.directions p {
    line-height: 1.4em;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1em;
}

.directions p:last-child {
    margin-bottom: 0;
}

p.caution {
    font-weight: bold;
    color: red;
}

.main-info p.caution {
    font-weight: bold;
    color: #FFD114;
}

p.caution::before {
    content: '⚠️ ';
}

a.link {
    text-decoration: none;
    color: rgb(18, 39, 48);
}

.main-info a.link {
    color: #FFD114;
    font-weight: bold;
}

.eBird {
}

.eBird-e {
    color: #4DA800;
}

.eBird-bird {
    color: white;
}

.hotspot a::after {
    content: ' 🔥';
}

.hotspot a {
    padding: 5px 10px;
    text-decoration: none;
    border: 0.5px solid rgb(160, 160, 160);
    border-radius: 4px;
    background-color:  rgb(18, 39, 48);
    font-family: serif;
    font-size: 24px;
    text-align: left;
}

.image-container {
    position: relative;
}

.credit {
    font-size: 8pt;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 5px 6px;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 1em;
    border-top-left-radius: 8px;
    color:white;
}

@media screen and (max-width: 400px) {
    .jumbotron .title, .location-title {
        font-weight: 500;
        margin: 0 20px;
    }
    .main-info img.left, .main-info img.right {
        width: 100%;
        margin-bottom: 2em;
    }
        /* .main-info p:first-child {
        margin-top: 1em;
    } */
    .main-info {
        padding: 20px;
        padding-top: 70px;
    }
    .directions {
        padding: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .jumbotron .title {
        font-size: 102px;
    }
    .location-title {
        font-size: 71px;
    }
}

@media screen and (max-width: 500px) {
    .location {
        width: 90vw;
        height: 121vw;
    }
    .locations li {
        margin-right: 0;
    }
}
  